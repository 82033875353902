import { Locale } from '@bemlo/enums'

import { Profession } from '../../generated/graphql'
import { factories } from '../factories'
import { pluralize } from '../utils'

import type { Translation, TranslationBase, TranslationNamespace } from './en'

const IS_JOBS_FEATURE_ENABLED = false

const COMPANY_NAME = 'Bemlo'
const COMPANY_NAME_POSSESSIVE = 'Bemlos'
const SITE_NAME = 'Bemlo'
const SITE_NAME_POSSESSIVE = 'Bemlos'

const jobs = pluralize('job', 'jobs')
const reviews_ = pluralize('anmeldelse', 'anmeldelser')
const salaries = pluralize('løn', 'løn')

const auth: TranslationNamespace<'auth'> = {
  choose_log_in_method: 'Vælg din loginmetode',
  click_link_in_email_to_log_in: 'Klik på linket i e-mailen for at logge på.',
  code: 'Kode',
  email_address_in_use: 'E-mailadressen er allerede i brug',
  have_not_registered_yet: 'Er du ikke tilmeldt endnu?',
  insert_code_sent_by_sms:
    'Indtast den kode, som du har fået tilsendt via sms.',
  log_in: 'Log på',
  log_in_at_our_site: `Log på ${SITE_NAME}`,
  log_in_description:
    'Se, hvilke opgaver og job vi har fundet til dig siden sidst.',
  log_in_with_email: 'Log på med e-mail',
  log_in_with_sms: 'Log på med SMS',
  no_account_connected_to_email:
    'Der er ingen konto knyttet til denne e-mailadresse',
  no_account_connected_to_phonenumber:
    'Der er ingen konto knyttet til dette telefonnummer',
  phone_number_in_use: 'Telefonnummeret er allerede i brug',
  register: 'Tilmeld dig',
  specify_email_you_used: `Indtast den e-mailadresse, du brugte, da du tilmeldte dig på ${SITE_NAME}.`,
  specify_phone_number_you_used: `Indtast det telefonnummer, du brugte, da du tilmeldte dig på ${SITE_NAME}.`,
}

const base: TranslationBase = {
  about_us: 'Om os',
  add: 'Tilføj',
  all_departments: 'Alle enheder',
  all_fields_must_be_provided: 'Alle felter skal udfyldes',
  all_professions: 'Alle job',
  all_regions: 'Alle regioner',
  all_specializations: 'Alle videregående uddannelser',
  apply_for_job: 'Søg',
  back: 'Tilbage',
  blog: 'Blog',
  cancel: 'Afbryd',
  collective_agreement: 'Overenskomst',
  comment_min_length: (minLength: number) =>
    `Kommentaren skal mindst indeholde ${minLength} tegn`,
  email: 'E-mail',
  email_placeholder: 'abc@xyz.dk',
  gathered_best_jobs_for_you_description:
    'Vi har samlet vikarjobs fra flere vikarbureauer og bemandingsvirksomheder, så du nemt kan finde det bureau, der passer bedst til dig.',
  invalid_email: 'E-mailadressen er ikke gyldig',
  loading: 'Indlæser...',
  log_in: 'Log på',
  mandatory: 'Obligatorisk',
  menu: 'Menu',
  name: 'Navn',
  name_placeholer: 'Jens Hansen',
  no: 'Nej',
  no_collective_agreement: 'Ingen overenskomst',
  no_match_found: 'Der blev ikke fundet nogen resultater',
  norway: 'Norge',
  obligatory: 'Obligatorisk',
  optional: 'valgfrit',
  other: 'Andet',
  phone_number: 'Telefonnummer',
  phone_placeholder: '+45 98 76 54 32',
  procurements: 'Anskaffelser',
  profession: 'Erhverv',
  read_more: 'Læs mere',
  region: 'Region',
  remove: 'Fjern',
  salary_statistics: 'Lønstatistik',
  select_profession: 'Vælg dit erhverv',
  share: 'Del',
  show_all: 'Vis alle',
  show_more: 'Vis flere',
  staffing_tips: 'Tips til bemanding',
  submit: 'Indsend',
  submitting: 'Indsender...',
  sweden: 'Sverige',
  table_of_contents: 'Oversigt',
  update: 'Opdater',
  whats_your_profession: 'Hvad er dit erhverv?',
  workers_in: (profession: Profession) =>
    base.$profession(profession, 'plural').toLowerCase(),
  yes: 'Ja',
  ...factories(Locale.DA),
}

const blog: TranslationNamespace<'blog'> = {
  blog_heading: 'Artikler til dem, der arbejder i sundhedssektoren',
  blog_paragraph_1: `På ${SITE_NAME} kan du finde artikler med relevante og nyttige oplysninger for ansatte i sundhedssektoren.`,
  blog_paragraph_2: 'Er der noget, du gerne vil have en artikel om?',
  comment: 'Kommentar',
  mail_us_at: 'Send en e-mail til',
  no_blog_posts_yet: 'Der er ingen blogindlæg endnu.',
  published: 'Publiceret',
  read_on: (siteName) => `Læs på ${siteName}`,
  reading_time: (minutes: number) => `${minutes} minutters læsning`,
  seo_title: 'Læs artikler om bemanding i sundhedssektoren',
  updated: 'Opdateret',
  write_a_comment: 'Skriv en kommentar',
}

const companiesList: TranslationNamespace<'companiesList'> = {
  best_for_doctors: 'De bedste vikarbureauer for læger',
  best_for_nurses: 'De bedste vikarbureauer for sygeplejersker',
  doctors: 'læger',
  find_best_companies: 'Find de bedste vikarbureauer',
  find_best_companies_for: (profession: string) =>
    `Find det bedste vikarbureau for ${profession}`,
  keywords: ['bedømmelser', 'bemandingsvirksomhed'],
  nurses: 'Sygeplejersker',
  nurses_and_doctors: 'sygeplejersker og læger',
  read_what_others_think: (professions: string) =>
    `Læs, hvad andre ${professions} har sagt, før du vælger dit næste vikarbureau. Vi har samlet alle vikarbureauer på ét sted og gjort det nemt at finde de bedste af dem.`,
  see_also: 'Se også:',
}

const companyFaq: TranslationNamespace<'companyFaq'> = {
  apply_now: 'Ansøg nu',
  does_company_have_collective_agreement: (name) =>
    `Har ${name} en overenskomst?`,
  how_many_consultants_work_at_company: (name) =>
    `Hvor mange konsulenter arbejder hos ${name}?`,
  no_company_does_not_have_collective_agreement: (name) =>
    `Nej – ${name} har ikke en kollektiv overenskomst.`,
  number_of_consultants_text: (count: number | null, name) =>
    count
      ? `Hvis man tæller fuldtidskonsulenter med, arbejder der ca. ${count} konsulenter hos ${name}.` +
        'Det er vanskeligt at fastsætte et nøjagtigt tal med sikkerhed.'
      : 'Vi har desværre ikke disse oplysninger.',
  read_more: 'Læs mere',
  yes_company_has_collective_agreement: (name) =>
    `Ja – ${name} har en kollektiv overenskomst!`,
}

const companyPage: TranslationNamespace<'companyPage'> = {
  about_company: (name) => `Om ${name}`,
  authorization_description:
    'Almega certificerer vikarbureauer, der har kollektive overenskomster og opfylder en række specifikke kriterier.',
  authorized_collective_agreement: 'Godkendt af Almega',
  company_job_postings: (name: string) => `${name} – Ledige stillinger`,
  company_page_description: (name) =>
    `Se, hvad andre mener om at arbejde hos ${name}. Se lønninger og hvilke regioner ` +
    `${name} er aktiv i. Ansøg om et job hos ${name} direkte på ${SITE_NAME}.`,
  find_jobs: 'Find job',
  go_back: 'Startside',
  go_to_company_list: 'Til virksomhedslisten',
  in_region: (region) => `i ${region}`,
  jobs: 'Job',
  new: 'Ny',
  no_procurements_found_for: (name) =>
    `Vi kunne ikke finde nogen offentlige stillinger for ${name}.`,
  other_top_ranked_companies: 'Andre virksomheder med høj rangering',
  read_more: 'Læs mere',
  respond_to_contact_request_from: (name) =>
    `Svar på kontaktanmodning fra ${name}`,
  reviews: 'anmeldelser',
  salaries: 'Løn',
  salary_statistics: 'Lønstatistik',
  see_other_consultant_salaries: (
    profession: Profession,
    regionCount: number,
  ) =>
    `Se lønninger for andre ${base.workers_in(profession)} i mere end` +
    `${regionCount} regioner.`,
  see_user_reported_salaries: (count?: number) =>
    `Se ${count ? ` ${count}` : ''} brugerregistrerede ${salaries(count)}`,
  see_what_others_think_of_company: (name) =>
    `Se, hvad andre mener om ${name}, og sammenlign virksomheder`,
  summary: 'Resumé',
  the_text_is_taken_from: (source: string) => `Teksten er hentet fra ${source}`,
  the_text_is_taken_from_companys_website: (name) =>
    `Teksten er hentet fra webstedet for ${name}`,
  you_and_company_are_in_contact: (name) => `Du og ${name} er i kontakt`,
}

const companyTable: TranslationNamespace<'companyTable'> = {
  ad: 'reklame',
  company: 'Virksomhed',
  find_companies: 'Find virksomheder',
  missing_companies_info: 'Mangler vi en virksomhed? Lad os vide det på',
  name: 'Navn',
  number_of_consultants: 'Antal midlertidigt ansatte',
  rating: 'Bedømmelse',
  rating_ascending: 'Bedømmelse, laveste først',
  rating_descending: 'Bedømmelse, højeste først',
  region: 'Region',
  showing_region: 'Viser region',
  sort: 'Sortér',
  sort_by: 'Sortér efter',
}

const ctaPanel: TranslationNamespace<'ctaPanel'> = {
  compare_companies: 'Sammenlign virksomheder',
  compare_staffing_companies_for: () => `Sammenlign mere end 50 vikarbureauer!`,
  easily_send_applications_to_companies:
    'Send nemt ansøgninger til virksomheder',
  see_information_about_salaries_and_benefits:
    'Se oplysninger om løn og fordele',
  see_reviews_from_others_in: (profession: Profession) =>
    `Se anmeldelser fra andre ${base.workers_in(profession)}`,
}

const deleteProfileRedirect: TranslationNamespace<'deleteProfileRedirect'> = {
  we_are_sorry_to_see_you_leaving: 'Vi beklager at se dig forlade Bemlo!',
  you_are_always_welcome_back: 'Du er altid velkommen tilbage',
}

const cookieConsent: TranslationNamespace<'cookieConsent'> = {
  accept_all: 'Godkend alle',
  accept_all_cookies: 'Godkend',
  accept_selected: 'Godkend valgte',
  analytics_cookies: 'Analysecookies',
  analytics_cookies_description:
    'Hjælper os med at forstå, hvordan webstedet bruges, så vi kan forbedre det.',
  choose_cookies: 'Vælg, hvilke cookies der kan gemmes i din browser.',
  cookies_description:
    'Vi bruger kun cookies for at forbedre din oplevelse på vores websted. Nogle cookies bruges til at få hjemmesiden til at fungere korrekt og andre til at forbedre din oplevelse. Ønsker du at acceptere alle cookies?',
  custom_selection: 'Vælg selv',
  how_we_use_cookies: 'Læs mere om, hvordan vi bruger cookies.',
  necessary_cookies: 'Nødvendige cookies',
  necessary_cookies_description:
    'Nødvendige cookies bruges for at få hjemmesiden til at fungere. De gør det muligt at udføre vigtige funktioner, f.eks. at logge på.',
  we_value_your_privacy: 'Vi beskytter dine personlige oplysninger',
}

const fallback: TranslationNamespace<'fallback'> = {
  an_error_occurred: 'Der opstod en fejl',
  to_the_start_page: 'Til startssiden',
  were_working_on_a_fix: 'Vi arbejder på at finde en løsning.',
}

const faq: TranslationNamespace<'faq'> = {
  get_answers_to_your_questions_about_staffing:
    'Få svar på dine spørgsmål om bemanding',
  no_questions: 'Vi fandt ikke nogen spørgsmål.',
  questions: 'Spørgsmål',
  subtitle: (email) => (
    <>
      Kan du ikke finde et svar på dit spørgsmål?
      <br />
      Kontakt os på{' '}
      <a href={`mailto:${email}`} style={{ color: 'white' }}>
        {email}
      </a>
      .
    </>
  ),
  title: `Ofte stillede spørgsmål om bemanding – ${SITE_NAME}`,
}

const footer: TranslationNamespace<'footer'> = {
  career_cta: 'Udvikler? Ansøg nu.',
  cookie_policy: 'Oplysninger om cookies',
}

const header: TranslationNamespace<'header'> = {
  find_a_job: 'Find et job',
  home: 'Start',
  jobs: 'Job',
  log_in: 'Log på',
  log_out: 'Log ud',
  my_profile: 'Min Profil',
  salary_calculator: 'Lønberegner',
  see_my_profile: 'Min Profil',
  show_the_page_for: 'Vis siden for',
  switch_language: 'På svenska',
  vardforbundet: 'Vårdförbundet',
}

const iePopup: TranslationNamespace<'iePopup'> = {
  download_chrome_here: 'Download Chrome her',
  page_does_not_work_with_ie:
    `Desværre fungerer ${SITE_NAME} ikke godt med Internet Explorer. ` +
    'Vi anbefaler derfor, at du skifter til en anden browser, f.eks. Chrome eller Firefox.',
}

const jobBoard: TranslationNamespace<'jobBoard'> = {
  apply: 'Søg',
  apply_by: 'Ansøg inden den',
  apply_directly_on_website: () => `Ansøg direkte på ${meta.site_name}`,
  bemlo_logotype_alt_text: 'Bemlos logotype',
  could_not_find_any_matching_published_vacancies:
    'Kunne ikke finde nogen matchende publicerede opgaver',
  could_not_find_any_matching_vacancies:
    'Kunne ikke finde nogen matchende opgaver',
  create_a_profile: 'Oppret profil',
  create_a_profile_description:
    'Opret en profil, og få tilbud på matchende opgaver direkte fra flere vikarbureauer.',
  department_other: 'Afdelinger',
  distance_work: 'Fjernarbejde',
  earliest_job_start: 'Tidligste jobstart',
  estimated: 'Anslået',
  for_the_job: 'på jobbet',
  full_time_description: 'Opgaven er på 100 %.',
  hour_short: 't',
  invoice_verb: 'Fakturering',
  irregular_part_time_description: 'Opgaven indebærer vagter nu og da,',
  job_board_paragraph:
    'Vi har indsamlet vikarjobs fra flere vikarbureauer. Som sygeplejerske og læge kan du nemt finde det, der passer bedst til dig. Find dit næste job i sundhedssektoren i dag.',
  job_mediated_by_company: (company: string) => `Job formidlet af ${company}`,
  job_start: 'Jobstart',
  jobs_page_title: 'Ledige stillinger i Danmark',
  match: 'match',
  month_short: 'mnd',
  new: 'Ny',
  no_description: 'Ingen beskrivelse',
  profession_title: 'Jobtitel',
  published_at_date: (date: string) => `Publiceret ${date}`,
  region: 'Region',
  region_header_alt_text: 'Billede af den region, hvor jobbet udføres',
  regular_part_time_description: 'Opgaven er på mellem 20-80 %.',
  reset_filters: 'Nulstil filtre',
  salary: (profession: Profession) =>
    profession === Profession.DOCTOR ? 'godtgørelse' : 'løn',
  show_all_departments: 'Vis alle afdelinger',
  showing_x_out_of_y_vacancies: (x: number, y: number) =>
    `Viser **${x}** af **${y}** annoncer`,
  specialization_other: 'Specialiseringer',
  staffing_job_in_region: (municipality: string) =>
    `Bemanding i ${municipality}`,
  the_job_is_located_in_municipality: (municipality: string) =>
    `Opgaven ligger i ${municipality}`,
  unknown_date: 'Ukendt dato',
  until_further_notice: 'Indtil videre',
  vacancies_for_company: (companyName?: string) =>
    `Ledige stillinger ${companyName ? ` hos ${companyName}` : ''}`,
  vacancies_for_nurses_and_doctors:
    'Ledige stillinger som sygeplejersker og læger i Danmark',
  week_number: (week: string) => `Uge ${week}`,
}

const jobPage: TranslationNamespace<'jobPage'> = {
  application_sent: 'Ansøgning sendt',
  apply_now: 'Ansøg nu',
  create_an_account: 'Create an account',
  create_an_account_description:
    'Do you want to know more about the workplace?',
  cta_section_heading:
    'Bliv matchet med det vikarjob, der passer perfekt til dig',
  cta_section_paragraph: 'Sammenlign virksomheder, og find dit drømmejob.',
  explore_similar_jobs: 'Se lignende job',
  open_map: 'Åbn kortet',
  see_more_jobs: 'Se flere job',
  see_the_jobs: 'Se job',
  your_workplace: 'Din arbejdsplads',
}

const landingPage: TranslationNamespace<'landingPage'> = {
  apply_now: 'Ansøg nu',
  find_the_best_staffing_company: 'Find det perfekte vikarbureau',
  seo_description_profession: (profession: Profession) =>
    `Se alle bemandingsjob til ${base.workers_in(profession)} ét sted`,
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        landing_page_heading_1: 'Bliv matchet med det vikarjob',
        landing_page_heading_2: 'der passer perfekt til dig',
        landing_page_paragraph_1: 'Er du klar til den løn, du fortjener?',
        landing_page_paragraph_2:
          'Sammenlign virksomheder, og find dit drømmejob.',
      }
    : {
        landing_page_heading_1: 'Find de bedste vikarbureauer',
        landing_page_heading_2: 'i sundhedssektoren',
        landing_page_paragraph_1:
          'Læs anmeldelser fra virksomhedernes medarbejdere, og lad bureauerne konkurrere om dig.',
        landing_page_paragraph_2: '',
      }),
  log_in_at_our_site: `Log på hos ${SITE_NAME}`,
  see_jobs: (count: number) => `Se ${count} ${jobs(count)}`,
  see_the_company_rankings: 'Se virksomhedens rangering',
  see_the_jobs: 'Se job',
  so_that_you_can_easily_compare_terms_and_salaries:
    'Så du nemt kan sammenligne vilkår og løn',
  we_present_staffing_jobs_from_many_companies:
    'Vi viser stillingsopslag fra mange virksomheder',
}

export const landingPage2: TranslationNamespace<'landingPage2'> = {
  apply_now: 'Søg nu',
  blog_button: 'Flere artikler',
  blog_heading: 'Livet som sygeplejerske på vikariat',
  blog_paragraph_for_profession: (profession: Profession) =>
    `På ${SITE_NAME}, publiceres der regelmæssigt artikler til ${base.workers_in(
      profession,
    )} for at dele information om erhvervet og livet som vikar.`,
  blog_paragraph_generic: `På ${SITE_NAME} publicerer vi artikler med det formål at sprede information om livet som vikar inden for sundhedsområdet..`,
  blog_read_more: 'Læs mere',
  companies_and_many_more: '...og mange flere',
  companies_based_on_reviews: (count: number) =>
    `Baseret på ${count} ${reviews_(count)}`,
  companies_heading: IS_JOBS_FEATURE_ENABLED
    ? 'Vi matcher dig med de bedste vikarbureauer.'
    : 'Vi har samlet vikarbureauerne.',
  companies_see_the_companies: 'Se virksomhederne',
  create_a_profile: 'Opret en profil',
  cta_button: IS_JOBS_FEATURE_ENABLED
    ? 'Find mit drømmejob'
    : 'Se virksomhederne',
  cta_heading_1: `${
    IS_JOBS_FEATURE_ENABLED ? 'Bliv matchet med' : 'Find'
  } vikarjob`,
  cta_heading_2: 'der passer bedst til dig',
  cta_paragraph:
    'Sammenlign virksomheder, og find den perfekte opgave til dig.',
  explanation_button: 'Kom i gang på 3 minutter',
  explanation_heading: `Sådan fungerer ${SITE_NAME}`,
  explanation_step_1_heading: 'Opret en profil',
  explanation_step_1_paragraph: (profession: Profession) =>
    `Ved at oprette en profil på ${SITE_NAME} kan du samle oplysningerne om dig, som ${base
      .$profession(profession)
      .toLowerCase()} på ét sted og lade vikarbureauer konkurrere om dig.`,
  explanation_step_1_paragraph_generic: `Ved at oprette en profil på ${SITE_NAME} kan du samle oplysningerne om dig på ét sted og lade vikarbureauer konkurrere om dig.`,
  explanation_step_2_heading: 'Få jobtilbud',
  explanation_step_2_paragraph:
    'Vi sætter vikarbureauer i kontakt med dig på baggrund af, hvornår og hvor du ønsker at arbejde, så du kan få de bedste jobtilbud.',
  explanation_step_3_heading: 'Begynd at arbejde',
  explanation_step_3_paragraph:
    'Vi sørger for, at du får så mange gode jobtilbud som muligt, så du kan vælge det job, der passer bedst til dig, uden at det kræver nogen ekstra indsats.',
  intro_button: 'Se job',
  intro_clarification: 'Helt gratis – kom i gang på 3 min!',
  ...(IS_JOBS_FEATURE_ENABLED
    ? {
        intro_heading_1: (profession: Profession) =>
          `Midlertidige job som ${base
            .$profession(profession)
            .toLowerCase()}? Vi har jobbene`,
        intro_heading_2: () => 'samlet på ét sted.',
        intro_heading_3: () => '',
        intro_paragraph_1: 'Klar til de vilkår, du fortjener?',
        intro_paragraph_2:
          'Vi matcher dig med de bedste job blandt 50+ vikarbureauer.',
      }
    : {
        intro_heading_1: (_: Profession) => <>Find de bedste vikarbureauer </>,
        intro_heading_2: (_: Profession) => ``,
        intro_heading_3: (profession: Profession) =>
          `til ${base.workers_in(profession)}.`,
        intro_paragraph_1: 'Læs anmeldelser fra tidligere medarbejdere,',
        intro_paragraph_2: 'og lad bureauerne konkurrere om dig.',
      }),
  are_you_a_care_giver: () => 'Er du en sundhedsinstitution?',
  intro_image_alt: (profession: Profession) =>
    `Et billede af en smilende ${base.$profession(profession).toLowerCase()}`,
  jobs_button: (count: number) => `Se ${count} andre ${jobs(count)}`,
  jobs_heading_for_profession: (profession: string) =>
    `Vi har jobbene til ${profession}`,
  jobs_heading_generic: `Vi har jobbene`,
  jobs_subscribe: 'Abonnér på jobtilbud',
  learn_1_buttonText: 'Beregn din løn',
  learn_1_paragraph: (profession: Profession) =>
    `Beregn, hvor meget du kan tjene som ${base
      .$profession(profession)
      .toLowerCase()} i din region, afdeling og på grundlag af din uddannelse og erfaring.`,
  learn_1_paragraph_generic: `Beregn, hvor meget du kan tjene i din region, afdeling og på grundlag af din uddannelse og erfaring.`,
  learn_1_title: 'Lønberegner',
  learn_2_buttonText: 'Sammenlign løn',
  learn_2_paragraph:
    'Se indsendte løntal fra kolleger med erfaring fra vikarbureauer.',
  learn_2_title: 'Lønstatistik',
  learn_3_buttonText: 'Til artiklerne',
  learn_3_paragraph:
    'Find svar på dine spørgsmål om vikarsektoren for sundhedspersonale. Hvordan får man de bedste vilkår?  Og meget mere.',
  learn_3_title: 'Vores blog',
  learn_4_buttonText: 'Se alle lønninger',
  learn_4_paragraph:
    'Find ud af, hvor og for hvilken virksomhed du kan få den højeste løn. Vi viser dig alle de kommunale og regionale overenskomster.',
  learn_4_title: 'Offentlige udbud',
  learn_5_buttonText: 'Til spørgsmålene',
  learn_5_paragraph:
    'Få svar på ofte stillede spørgsmål om at arbejde for et vikarbureau.',
  learn_5_title: 'Ofte stillede spørgsmål',
  learn_heading: 'Få mere at vide om bemandingsbranchen',
  product_agency_management: () => 'Bureauadministration',
  product_ai_scheduling: () => 'AI-planlægning',
  product_pools: () => 'Vikarmanagement',
  see_bemlos_tools: () =>
    'Se Bemlos markedsledende værktøjer til planlægning og vikarhåndtering',
}

/**
 * TODO: Add support for using multiple namespaces, and add a "form" namespace.
 */
const leads: TranslationNamespace<'leads'> = {
  companyHeading: (name) => `Er du interesseret i at arbejde for ${name}?`,
  education: 'Hvilken uddannelse (herunder videreuddannelse) har du?',
  education_placeholder: 'Jeg har en videreuddannelse i...',
  heading:
    'Når du ansøger gennem Bemlo, får du flere tilbud, som du kan sammenligne',
  interests: 'Hvilken slags arbejde er du interesseret i?',
  interests_placeholder: 'Jeg vil gerne arbejde med...',
  pageTitle: 'Søg efter vikarjob på 60 sekunder',
  paragraph:
    'Indsend formularen nedenfor, og så kontakter vi dig, så snart vi finder en passende stilling.',
  phone_number: 'Telefonnummer',
  profession: 'Inden for hvilken erhvervsgruppe søger du et job?',
  submit: 'Indsend',
  thank_you_for_submitting:
    'Tak for din interesse! Vi kontakter dig snarest muligt.',
  when_to_work: 'Hvornår kan du begynde, og hvor længe ønsker du at arbejde?',
  when_to_work_placeholder: 'Jeg ønsker at arbejde...',
  where_to_work: 'Hvor vil du gerne arbejde?',
  where_to_work_placeholder: 'Jeg ønsker at arbejde i nærheden af...',
}

const menuItems: TranslationNamespace<'menuItems'> = {
  about_us: 'Om os',
  blog: 'Artikler',
  companies: 'Rangering af vikarbureauer',
  jobs: 'Job',
  procurements: 'anskaffelser',
  register: 'Oversigt',
  salary_calculator: 'Lønberegner',
  salary_statistics: 'Lønstatistik',
  staffing_tips: 'Ofte stillede spørgsmål',
  vardforbundet: 'Norsk Sykepleierforbund',
}

const meta: TranslationNamespace<'meta'> = {
  company_name: COMPANY_NAME,
  company_name_possessive: COMPANY_NAME_POSSESSIVE,
  site_description:
    'Sammenlign hurtigt og nemt vikarbureauer inden for sundhedssektoren',
  site_keywords: ['bemlo', 'sundhedsvæsen', 'bemanding'],
  site_name: SITE_NAME,
  site_name_possessive: SITE_NAME_POSSESSIVE,
}

const notFound: TranslationNamespace<'notFound'> = {
  page_not_found: 'Siden blev ikke fundet',
  to_the_start_page: 'Til forsiden',
  you_found_a_page_that_doesnt_exist:
    'Den side, du har anmodet om, findes ikke.',
}

const phone_number: TranslationNamespace<'phone_number'> = {
  enter_valid_number_which_country_code:
    'Indtast et gyldigt telefonnummer med landekode (+xx)',
  invalid: 'Ugyldigt telefonnummer',
  mandatory_field: 'Telefonnummer er et obligatorisk felt',
  too_short: 'Telefonnummeret er for kort',
}

const sentry: TranslationNamespace<'sentry'> = {
  errorFormEntry: 'Nogle felter var ugyldige. Ret fejlene og prøv igen.',
  errorGeneric:
    'Der opstod en ukendt fejl under indsendelse af formularen. Prøv igen.',
  labelClose: 'Luk',
  labelComments: 'Hvad skete der?',
  labelEmail: 'E-mail',
  labelName: 'Navn',
  labelSubmit: 'Indsend',
  subtitle: 'Vores team er blevet underrettet',
  subtitle2:
    'Hvis du vil hjælpe os, kan du fortælle os, hvad der skete nedenfor.',
  successMessage: 'Din feedback er blevet indsendt. Mange tak!',
  title: 'Det ser ud til, at vi har problemer.',
}

const reviews: TranslationNamespace<'reviews'> = {
  be_the_first_reviewer_of: (name) =>
    `Bliv den første, til at anmelde ${name} ved at bruge formularen ovenfor.`,
  comments: 'Kommentarer',
  confirm: 'Bekræft',
  email: 'E-mail',
  email_must_be_valid:
    'Hvis din e-mail er ugyldig, vil din anmeldelse ikke blive offentliggjort.',
  go_back: 'Gå tilbage',
  guest: 'Gæst',
  have_you_worked_at_company: (name) => `Har du arbejdet hos ${name}?`,
  have_you_worked_at_company_info: () =>
    'Vi accepterer kun anmeldelser fra tidligere eller nuværende medarbejdere.',
  help_others: (name) => (
    <>
      Har du erfaring med at arbejde hos <strong>{name}</strong>? Hjælp andre
      ved at skrive en anmeldelse!
    </>
  ),
  leave_a_comment: 'Skriv en kommentar',
  license_number: 'AutorisationsID (deles aldrig)',
  moderator: 'Moderator',
  name: 'Navn',
  next: 'Næste',
  no_reviews_available: 'Der er ingen anmeldelser tilgængelige.',
  only_show_reviews_from: 'Vis kun anmeldelser fra',
  please_leave_a_star_rating: 'Giv en stjernebedømmelse',
  rate: (name) => `Bedøm ${name}`,
  rating: 'Bedømmelse',
  review: 'Anmeldelse',
  review_could_not_be_submitted:
    'Din anmeldelse kunne ikke indsendes. Prøv igen',
  review_fraud_warning_subtitle: 'Vi arbejder aktivt på at forhindre snyd.',
  review_fraud_warning_title:
    'En eller flere anmeldelser af denne virksomhed er blevet fjernet på grund af tvivl om deres legitimitet',
  review_security_information: (
    <>
      Ved at indsende din anmeldelse bekræfter du, at:
      <ul>
        <li>Du har arbejdet i den virksomhed, du anmelder</li>
        <li>Du er en autoriseret læge eller sygeplejerske</li>
        <li>Indholdet af din anmeldelse er 100 % sandt</li>
      </ul>
      Dine personlige oplysninger vil <b>aldrig</b> blive delt med nogen uden
      for {SITE_NAME}.
    </>
  ),
  start_bankid: 'Start BanID',
  start_bankid_paragraph:
    'Start BankID-appen på din mobilenhed, og scan QR-koden.',
  terms_and_conditions: 'vilkår og betingelser',
  thanks_for_sharing_your_experience: 'Tak, fordi du deler din erfaring!',
  trust_text:
    `${SITE_NAME} er bygget på tillid, og at brugernes anmeldelser er ærlige. ` +
    'Vi beder dig derfor om kun at skrive anmeldelser af virksomheder, som du selv har arbejdet for - det er til gavn for alle!',
  we_take_measures_against_manipulation:
    'Vi arbejder aktivt på at forhindre manipulation af ranglisten.',
  when_you_submit_you_accept_our:
    'Ved at indsende bekræfter du, at du accepterer vores',
  write_a_review_for: (name) => `Skriv en anmeldelse af ${name}...`,
  xxx: 'XXX',
  your_information_will_not_be_shared_with: (name) =>
    `Dine oplysninger vil ikke blive delt med ${name} eller nogen andre.`,
}

const tellMeMore: TranslationNamespace<'tellMeMore'> = {
  email: 'E-mail',
  tell_me_more_completed: 'Registreret',
  tell_me_more_cta: 'Fortæl mig mere!',
  tell_me_more_subtitle: `Med ${SITE_NAME} kan du nemt sammenligne og få tilbud fra de bedst vurderede vikarbureauer.`,
  tell_me_more_title: 'Ønsker du en bedre løn?',
}

const registrationForm: TranslationNamespace<'registrationForm'> = {
  add_profile_information_so_that:
    'Efterlad dine kontaktoplysninger, så arbejdsgiverne kan kontakte dig om spændende jobtilbud, der matcher dine præferencer',
  additional_contacts_more_label:
    'Jeg vil gerne have kontakt med flere virksomheder',
  additional_contacts_more_subtitle: (sitename) =>
    `Jeg ønsker at ${sitename} skal matche mig med flere gode virksomheder`,
  additional_contacts_none_label: 'Ikke flere virksomheder',
  additional_contacts_none_subtitle: 'Kun til den pågældende virksomhed',
  additional_contacts_title:
    'Ønsker du at komme i kontakt med flere virksomheder?',
  additional_contacts_url_extension_string: 'kontakter',
  apply_to: (numberOfSelectedEmployers?: number) =>
    `Rapportér interesse for ${numberOfSelectedEmployers} virksomhed`,
  biography_alternatives_free_text_placeholder:
    'Jeg søger opgaver, hvor... (fritekst) (valgfrit)',
  biography_title: 'Beskriv dig selv kort.',
  biography_url_extension_string: 'biografi',
  boom: 'Sådan!',
  confirm_application: 'Bekræft registreringen',
  contact_details_email_error_format: 'Indtast en gyldig e-mailadresse',
  contact_details_email_error_obligatory: 'E-mail er et obligatorisk felt',
  contact_details_email_suggestion: (email: string) => `Mente du ${email}?`,
  contact_details_email_title: 'E-mail',
  contact_details_error_message: 'Indtast dit for- og efternavn',
  contact_details_name_title: 'Forn- og efternavn',
  contact_details_title: 'Udfyld dine kontaktoplysninger',
  current_employment_status_subtitle: 'Hvilket udsagn passer bedst til dig?',
  current_employment_status_subtitle_update:
    'Hvilken mulighed passer bedst til din nuværende jobsituation?',
  current_employment_status_title: 'Hvordan er din nuværende arbejdssituation?',
  current_employment_status_title_update: 'Det er længe siden, vi har set dig!',
  current_employment_status_url_extension_string: 'i dag',
  departments_interests_alternatives_box_title: 'Afdelinger',
  departments_interests_free_text_placeholder:
    'Jeg er interesseret i at arbejde på følgende afdelinger... (fritekst)',
  departments_interests_free_text_title: 'Ingen passende alternativer?',
  departments_interests_title: 'Hvilke afdelinger er du interesseret i?',
  departments_interests_url_extension_string: 'afdelinger',
  error_action_back_to: (siteName: string) => `Tilbage til ${siteName}`,
  error_action_try_again: 'Prøv igen',
  error_paragraph_1: (email: string) => (
    <>
      Noget gik galt, da ansøgningen blev sendt. Du er velkommen til at kontakte
      os direkte på{' '}
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {email}
      </a>{' '}
      så hjælper vi dig der.
    </>
  ),
  error_paragraph_2: 'Vores team arbejder hårdt på at løse problemet.',
  error_title: 'Der gik noget galt',
  existing_account_subtitle: (siteName: string) =>
    `Det ser ud til, at du allerede har en konto på ${siteName}.`,
  existing_account_title: (siteName: string) =>
    `Log ind på din ${siteName}-konto`,
  experience_alternatives_box_title: 'Erfaring',
  experience_free_text_placeholder: 'Jeg har arbejdet... (fri tekst)',
  experience_free_text_title: 'Intet passende alternativ?',
  experience_title: 'Hvor meget erfaring har du??',
  experience_url_extension_string: 'erfaring',
  home_municipality_alternatives_box_title: 'Hjemmekommune',
  home_municipality_box_placeholder: 'Kommune...',
  home_municipality_extension_string: 'erfaring',
  home_municipality_free_text_placeholder: 'Nær...',
  home_municipality_free_text_title: 'Kommentar',
  home_municipality_title: 'Hvor bor du i dag?',
  how_much_work_full_time_description:
    'Jeg er interesseret i at arbejde på fuld tid',
  how_much_work_irregular_part_time_description:
    'Jeg vil tage en vagt i ny og næ',
  how_much_work_part_time_description: 'Jeg søger stillinger mellem 20-80%',
  how_much_work_title: 'Hvor meget er du interesseret i at arbejde?',
  how_much_work_url_extension_string: 'arbejdsbyrde',
  job_types_irregular_parttime_subtitle:
    'Timer og vagter i private eller offentlige sundhedsorganisationer',
  job_types_permanent_subtitle:
    'Fastansættelse i private eller offentlige sundhedsorganisationer.',
  job_types_staffing_subtitle:
    'Kortere eller længerevarende opgaver for bemandingsvirksomheder.',
  job_types_title: 'Hvilke typer af arbejde er du interesseret i?',
  job_types_url_extension_string: 'arbejde',
  journal_system_alternatives_box_placeholder: 'Journalsystem...',
  journal_system_alternatives_box_title: 'Journalsystem',
  journal_system_alternatives_free_text_placeholder:
    'Jeg har arbejdet med... (fritekst)',
  journal_system_alternatives_free_text_title:
    'Mangler den et eller flere systemer?',
  journal_system_title: 'Hvilke journalsystemer har du arbejdet med?',
  journal_system_url_extension_string: 'journalsystem',
  loading: 'Indlæser',
  move_back: 'Tilbage',
  move_forward: 'Fortsæt',
  norwegian_license_subtitle: 'Har du en norsk autorisation?',
  norwegian_license_title: 'Norsk autorisation',
  norwegian_license_url_extension_string: 'norsk-autorisation',
  page_description: 'Søg efter vikarjob på 60 sekunder',
  page_title: 'Søg efter vikarjob på 60 sekunder',
  please_continue_improving_your_profile:
    'Bliv ved med at svare på spørgsmål for at færdiggøre din profil.',
  please_tell_us_more_about_you: 'Fortæl os mere om dig selv',
  profession_title: 'Hvad er dit erhverv?',
  profession_url_extension_string: 'erhverv',
  reference_add_first_reference: 'Tilføj din første reference',
  reference_add_reference: 'Tilføj reference',
  reference_add_reference_description:
    'Vi anbefaler, at du inviterer din tidligere chef og to kolleger.',
  reference_added_at: (at: string) => `Tilføjet (${at})`,
  reference_button: (hasReferences: boolean) =>
    `Invitér ${hasReferences ? 'flere ' : ''}referencer`,
  reference_close: 'Luk',
  reference_entered_at: (at: string) => `Reference blev indsendt (${at})`,
  reference_extension_string: 'referencer',
  reference_no_references: 'Du har endnu ikke tilføjet nogen referencer.',
  reference_save_and_close: 'Gem og luk',
  reference_subtitle:
    'Anmod om referencer via Bemlo for at spare tid for dig selv, den refererende person og bemandingsvirksomhederne. Den refererende person vil modtage en e-mail med et link, hvor de hurtigt kan komme med en anbefaling om dig.',
  reference_title: 'Angiv reference',
  reference_update_reference: 'Opdater reference',
  reference_your_description:
    'Referencerne deles automatisk med de arbejdsgivere, du er blevet matchet med.',
  reference_your_title: 'Dine referencer',
  send_application: 'Send registreringen',
  send_new_application_to: (name?: string) =>
    `Ønsker du at indsende en ny interesseerklæring ${
      name ? ` til ${name}` : ''
    }?`,
  skip_question: 'Spring over',
  specialization_alternatives_box_title: 'Videreuddannelse',
  specialization_doctor_url_extension_string: 'speciallæge',
  specialization_free_text_placeholder: 'Videreuddannelse i ... (fritekst)',
  specialization_free_text_title: 'Kan du ikke finde din videreuddannelse?',
  specialization_nurse_url_extension_string: 'specialuddannet sygeplejerske',
  specialization_title: 'Har du nogen videreuddannelse?',
  success_welcome_message: 'Nu er det første trin afsluttet.',
  swedish_license_subtitle: 'Har du en svensk autorisation?',
  swedish_license_title: 'Svensk autorisation',
  swedish_license_url_extension_string: 'svensk-autorisation',
  to_company: (name?: string) => `Til ${name ?? 'firmaet'}`,
  to_jobs: 'Til ledige stillinger',
  waiting_for_answers: 'Afventer svar',
  we_recommend_picking_1_3_companies:
    'Vi anbefaler, at du vælger 1-3 virksomheder',
  were_handling_your_application:
    'Vi behandler din ansøgning så hurtigt, som vi kan.',
  when_to_start_exact_placeholder: 'Vælg dato',
  when_to_start_exact_title: 'Hvornår kan du tidligst begynde?',
  when_to_start_exact_url_extension_string: 'tidligst',
  which_companies_do_you_want_to_recieve_offers_from:
    'Hvilke virksomheder ønsker du at få tilbud fra?',
  which_countries_extension_string: 'land',
  which_countries_title: 'I hvilket land er du interesseret i at arbejde?',
  which_regions_affected_by_karens_alternatives_label: (alternative: string) =>
    `Region ${alternative}`,
  which_regions_affected_by_karens_box_placeholder: 'Region...',
  which_regions_affected_by_karens_box_title: 'Regioner',
  which_regions_affected_by_karens_free_text_placeholder:
    'Det er sådan at ... (fritekst)',
  which_regions_affected_by_karens_free_text_title:
    'Er der noget, du ønsker at tilføje?',
  which_regions_affected_by_karens_string: 'karens',
  which_regions_affected_by_karens_title:
    'Har du arbejdet for en af disse arbejdsgivere inden for de seneste 6 måneder?',
  which_regions_to_work_in_alternatives_box_title: 'Regioner',
  which_regions_to_work_in_free_text_placeholder:
    'Jeg kan arbejde i... (fritekst)',
  which_regions_to_work_in_free_text_title: 'Intet passende alternativ?',
  which_regions_to_work_in_title: 'I hvilke regioner vil du gerne arbejde?',
  which_work_form_subtitle: 'Hvordan ønsker du at arbejde?',
  which_work_form_title: 'Ønsket form for ansættelseskontrakt',
  which_work_form_url_extension_string: 'arbejdskontrakt',
  your_profession_does_not_match_the_vacancy_profession:
    'Din registrerede profession matcher ikke den ledige stilling',
  youre_application_have_been_sent: 'Din ansøgning er blevet sendt',
  youve_recently_sent_an_application:
    'Du har for nylig indgivet en interesseerklæring.',
}

const contactRequest = {
  already_in_contact: 'Du er allerede i kontakt med denne arbejdsgiver',
  contact_request_reject_header: (employerName: string) =>
    `Afvis anmodning fra ${employerName}`,
  contact_request_reject_header_subheader: 'Ønsker du at afvise anmodningen?',
  decline: 'Afvis',
  dislike_company: 'Jeg er ikke interesseret i at arbejde for denne virksomhed',
  do_you_want_accept_the_contact_request: 'Ønsker du at acceptere?',
  free_text_extra_comment: 'Noget du vil tilføje?',
  free_text_extra_comment_placeholder: 'Hvorfor... (fritekst)',
  reason: 'Årsag',
  select: 'Vælg...',
}

const profile: TranslationNamespace<'profile'> = {
  about_you: 'Om dig',
  accept: 'Accepter',
  add_authorization: 'Tilføj autorisation',
  add_authorization_description: 'Tilføj dit autorisationsID',
  add_authorization_error: 'Indtast venligst et gyldigt autorisationsnummer',
  add_authorization_title: 'Mellem 6 og 9 cifre',
  answer: 'Svar',
  application_is_sent: 'Ansøgningen er blevet sendt',
  apply_cta_subtitle: 'Find dit næste drømmejob',
  apply_cta_title: 'Er du klar til nye opgaver?',
  authorization: 'Autorisation',
  back: 'Tilbage',
  bio: 'Biografi',
  close: 'Luk',
  contacts: 'Kontakter',
  countries: 'Land',
  current_employee_status: 'Nuværende stilling',
  decline: 'Afvis',
  delete_document: 'Slet dokumenter ',
  delete_file: (fileName: string) => 'Slet ' + fileName,
  delete_profile: 'Slet profil',
  delete_profile_confirmation:
    'Er du sikker på, at du vil slette din profil? Dette kan ikke fortrydes.',
  delete_profile_description:
    'Din profil vil blive slettet permanent. Dette kan ikke fortrydes.',
  departments: 'Virksomheder',
  do_you_want_to_upload_this_file: 'Ønsker du at uploade filen?',
  document_one: 'Dokument',
  document_other: 'Dokumenter',
  download_document: 'Download dokumenter ',
  download_file: (fileName: string) => 'Download ' + fileName,
  email: 'E-mail',
  employers_in_contact_with_you: 'Arbejdsgivere, du er i kontakt med',
  experience: 'Erfaring',
  file_could_not_be_found: (email: string) =>
    'Filen kunne ikke findes. Prøv igen eller kontakt os på ' + email,
  file_is_uploaded: 'Filen er blevet uploadet!',
  file_was_not_accepted: 'Filen blev ikke accepteret, prøv med en anden fil',
  hidden_from_employers: 'Skjult for arbejdsgiverne',
  home_municipality: 'Bopæl',
  invite_reference: 'Invitér referencer',
  is_uploading_file: 'Upload fil',
  jobs: 'Job',
  journal_systems: 'Journalsystem',
  matching_criteria: 'Matchende kriterier',
  matching_job_emails: 'Modtag e-mail',
  matching_job_emails_description: 'Modtag e-mails med matchende jobmuligheder',
  my_profile: 'Min profil',
  n_years_of_experience: (years: number) => {
    if (!years) return 'No experience'
    return years === 1 ? '1 year of experience' : `${years} years of experience`
  },
  name: 'Navn',
  new_contact_requests_from_employers:
    'Nye anmodninger om kontakt fra arbejdsgivere',
  no_matching_job_emails: 'Modtag ikke e-mails om matchende jobmuligheder',
  no_matching_jobs:
    'Vi anbefaler, at du sender en ny generel eller direkte ansøgning. Vi kunne desværre ikke finde nogen tilsvarende offentliggjorte job',
  no_matching_jobs_description:
    'Hvis du opdaterer din profil med flere regioner og afdelinger, vil du øge dine chancer for at blive matchet med offentliggjorte job.',
  norwegian_license: 'Norsk autorisation',
  not_added: 'Ikke fuldført',
  once_we_find_a_job:
    'Når vi finder et match, kontakter bemandingsfirmaerne dig for at drøfte tilbud og aftaler.',
  open_for_new_opportunities: 'Åben over for nye muligheder',
  overview: 'Oversigt',
  phone_number: 'Telefonnummer',
  profession: 'Erhver',
  reapply: 'Ansøg igen',
  reapply_explanation:
    'Vi genstarter matchningsprocessen og finder nye vikarbureauer, der matcher din profil.',
  reapply_question: 'Ønsker du at ansøge igen?',
  receive_job_offers: 'Modtager anonyme jobtilbud',
  regions: 'Regioner',
  replace_file: (fileName: string) => 'Udskift ' + fileName,
  replace_with_new_document: 'Udskift med nyt dokument ',
  scope: 'Omfang',
  see_all_jobs: 'Se alle job',
  select_files_to_upload: 'Vælg de filer, der skal uploades',
  specialization: 'Specialisering',
  staffing_agencies_contact_you: 'Bemandingsvirksomheder vil kontakte dig',
  start_date: 'Startdato',
  swedish_license: 'Svensk autorisation',
  the_more_flexible_you_are:
    'Jo mere fleksibel du er, jo større er sandsynligheden for, at vi kan finde opgaver, der passer til dig, og det er nemmest at få et job, hvis du er fleksibel med din pendling.',
  to_your_profile: 'Til din profil',
  type_of_document: 'Dokumenttype ...',
  type_of_employment: 'Ansættelsesform',
  update_email: 'Opdater e-mail',
  update_name: 'Opdater navn',
  update_phone_number: 'Opdater telefonnummer',
  update_profile: 'Opdater profil',
  upload: 'Upload',
  upload_cv: 'Upload dit CV',
  upload_cv_description: 'Pift din profil op med dit CV',
  upload_document: 'Upload dokumenter',
  upload_document_description: 'Upload et valgfrit dokument',
  upload_license: 'Upload autorisation',
  upload_license_description:
    'Spar tid for dig selv og for bemandingsvirksomhederne',
  upload_new_file: 'Upload ny fil',
  upload_specialization_document: 'Upload videreuddannelse',
  upload_specialization_document_description: '',
  uploaded_by_you_on: (date: string) => 'Uploadet af dig ' + date,
  we_have_sent_you_application: (companyName?: string) =>
    `Vi har sendt din ansøgning${
      companyName !== '' ? ` til ${companyName}` : ''
    }!`,
  we_use_matching_to_find_jobs:
    'Vi bruger de matchende kriterier til automatisk at finde opgaver, der passer til dig.',
  what_does_matching_mean: 'Hvad indebærer matchende kriterier?',
  what_happens_now: 'Hvad sker der nu?',
  work_form: 'Ønsket arbejdsform',
  you_are_not_in_contact_with_anyone: 'Du er ikke i kontakt med nogen endnu.',
  you_have_selected: (fileName: string) => 'Du har valgt ' + fileName,
  you_pick_which_companies: 'Du vælger den opgave, der passer bedst til dig',
  your_application_has_been_sent: 'Din ansøgning er blevet sendt',
}

/**
 * Danish [da]
 */
const da: Translation = {
  auth,
  base,
  blog,
  companiesList,
  companyFaq,
  companyPage,
  companyTable,
  contactRequest,
  cookieConsent,
  ctaPanel,
  deleteProfileRedirect,
  fallback,
  faq,
  footer,
  header,
  iePopup,
  jobBoard,
  jobPage,
  landingPage,
  landingPage2,
  leads,
  menuItems,
  meta,
  notFound,
  phone_number,
  profile,
  registrationForm,
  reviews,
  sentry,
  tellMeMore,
}

export const locale = Locale.DA

export default da
